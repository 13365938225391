<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <h5 slot="start">
          <IconCart class="message-input-tool icon-small" />
          Find Suggested Products
        </h5>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-education-modal-content">
      <ion-slides pager="false" ref="productSlider">
        <ion-slide>
          <div id="educationSearch">
            <input v-model="searchTerm" @keyup.enter="getSuggestedProducts" class="form-control large-input" type="text" placeholder="Search for products..." />
            <ion-icon id="educationSearchButton" name="search" @click="getSuggestedProducts" />
          </div>
          <div v-if="searchLoading" class="prime-education-modal-search-content center-center">
            <ion-spinner name="dots" duration="1200" color="black" />
          </div>
          <div v-if="!searchLoading" class="prime-education-modal-search-content">
            <ProductPreviewCard v-for="preview in productPreviews" :key="preview.product.id" @loaderClick="goToLoader(preview.product)" :preview="preview" />
          </div>
        </ion-slide>
        <ion-slide>
          <div class="center-center full-width" v-if="loaderIsLoading">
            <ion-spinner name="dots" duration="1200" />
          </div>
          <div style="overflow: auto;">
            <iframe v-if="!loaderIsLoading" :src="currentIframeUrl" width="760px" height="1000px"></iframe>
          </div>
        </ion-slide>
      </ion-slides>
    </ion-content>
    <ion-footer v-if="sliderFocus === 'loader'">
      <ion-grid>
        <ion-row>
          <ion-col>
            <button @click="goToSearch" class="prime-button button-secondary button-block">
              Back to Search Results
            </button>
          </ion-col>
          <ion-col>
            <button @click="sendProduct" class="prime-button button-block" :class="{ 'button-pending': sendProductLoading }">
              <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
              <span>Send Suggested Product Link</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import ProductPreviewCard from "@/components/Education/ProductPreviewCard";
import { EventBus } from "@/services/Events";
import { send as httpSend } from "@/services/Api";
import IconCart from "@/components/Global/Icons/IconCart";

const recommendHealth = {
  iframe: "https://recommend.health/Product",
  apiGetAll: "https://api.recommend.health/Product/All"
};

export default {
  name: "EducationModal",
  components: {
    IconCart,
    ProductPreviewCard
  },
  props: {
    beneficiaryId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      pageCopy: this.$languagePack.education,
      sendProductLoading: false,
      searchLoading: false,
      articleContent: {
        body: "",
        head: "",
        title: ""
      },
      swiperOption: {
        allowTouchMove: false,
        spaceBetween: 40,
        autoHeight: false
      },
      sliderFocus: "search",
      articleId: 0,
      loaderIsLoading: true,
      productPreviews: {},
      searchTerm: "",
      currentIframeUrl: ""
    };
  },
  mounted() {
    const slides = this.$refs.productSlider;
    slides.options = this.swiperOption;
  },
  methods: {
    async goToLoader(product) {
      const frameUrl = `${recommendHealth.iframe}/${product.id}/${product.urlName}`;
      this.currentIframeUrl = frameUrl;
      this.loaderIsLoading = true;
      this.$refs.productSlider.slideTo(1);
      this.loaderIsLoading = false;
      this.sliderFocus = "loader";
    },
    goToSearch() {
      this.sliderFocus = "search";
      this.$refs.productSlider.slideTo(0);
    },
    closeModal() {
      this.$ionic.modalController.dismiss({ description: null });
    },
    async getSuggestedProducts() {
      this.searchLoading = true;
      const method = "POST";
      const path = recommendHealth.apiGetAll;
      const payload = {
        searchString: this.searchTerm
      };

      try {
        const result = await httpSend({
          path,
          method,
          payload
        });
        const productPreviews = result.data.data.allProducts;
        this.productPreviews = result.data.data.allProducts;
        if (!productPreviews) {
          this.handleError("No articles found", this.pageCopy.errors.noArticles);
        }
      } catch (error) {
        this.handleError(error, this.pageCopy.errors.searchError);
      } finally {
        this.searchLoading = false;
      }
    },
    async sendProduct() {
      this.sendProductLoading = true;
      const method = "POST";
      const path = document.config.tinyUrl;

      const payload = {
        url: this.currentIframeUrl,
        additionalData: new Date()
      };

      try {
        const result = await httpSend({
          path,
          payload,
          method,
          authToken: true
        });

        const tinyUrl = result.data.url;
        EventBus.$emit("addToMessageInput", tinyUrl);
        this.closeModal();
      } catch (error) {
        this.handleError(error, this.pageCopy.errors.sendError);
      } finally {
        this.sendProductLoading = false;
      }
    },
    handleError(error, message) {
      this.$ionic.toastController
        .create({
          header: message,
          message: error,
          duration: 10000,
          position: "top"
        })
        .then(m => m.present());
      khanSolo.log(error);
    }
  }
};
</script>

<style scoped>
.swiper-container {
  height: 100%;
}
.swiper-slide {
  flex-direction: column;
  align-items: start;
}
.prime-education-modal-content {
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
}
.prime-education-modal-search-content {
  background: var(--ion-modal-secondary-background);
  border-top: 2px solid var(--ion-color-light-shade);
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow: auto;
}
ion-footer {
  padding: 20px;
}
ion-footer ion-col:first-child {
  padding-right: 5px;
}
ion-footer ion-col:not(:first-child) {
  padding-left: 5px;
}
:focus {
  outline: none !important;
}
#educationSearch {
  position: relative;
  padding: 0 20px;
  margin-bottom: 20px;
  width: 100%;
}
#educationSearchButton {
  position: absolute;
  cursor: pointer;
  color: var(--ion-color-medium-shade);
  height: 24px;
  width: 24px;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 40px;
}
#educationSearchButton:hover {
  color: var(--ion-color-primary);
}
iframe {
  border: 0;
}

.prime-modal ion-header ion-toolbar svg {
  margin-right: 5px;
  vertical-align: bottom;
}
</style>
