<template>
  <div>
    <ion-grid @click="sendEmoji($event)" class="emojis text-center cursor-pointer pad-top-ten pad-bottom-ten margin-lr-five">
      <ion-row>
        <h5 class="text-muted margin-top-five">People</h5>
      </ion-row>
      <ion-row class="ion-align-items-center ion-justify-content-center">
        <ion-col v-for="(emoji, key) in emojis.people" :key="key" v-html="emoji" size="auto" :class="emojiClass"></ion-col>
      </ion-row>
      <ion-row>
        <h5 class="text-muted margin-top-five">Nature</h5>
      </ion-row>
      <ion-row class="ion-align-items-center ion-justify-content-center">
        <ion-col v-for="(emoji, key) in emojis.nature" :key="key" v-html="emoji" size="auto" :class="emojiClass"></ion-col>
      </ion-row>
      <ion-row>
        <h5 class="text-muted margin-top-five">Objects</h5>
      </ion-row>
      <ion-row class="ion-align-items-center ion-justify-content-center">
        <ion-col v-for="(emoji, key) in emojis.objects" :key="key" v-html="emoji" size="auto" :class="emojiClass"></ion-col>
      </ion-row>
      <ion-row>
        <h5 class="text-muted margin-top-five">Places</h5>
      </ion-row>
      <ion-row class="ion-align-items-center ion-justify-content-center">
        <ion-col v-for="(emoji, key) in emojis.places" :key="key" v-html="emoji" size="auto" :class="emojiClass"></ion-col>
      </ion-row>
      <ion-row>
        <h5 class="text-muted margin-top-five">Symbols</h5>
      </ion-row>
      <ion-row class="ion-align-items-center ion-justify-content-center">
        <ion-col v-for="(emoji, key) in emojis.places" :key="key" v-html="emoji" size="auto" :class="emojiClass"></ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import { EventBus } from "@/services/Events";
import emojis from "@/assets/languagePack/emojis";

export default {
  name: "popoverEmojis",
  data() {
    return {
      emojis,
      emojiClass: "emoji-col"
    };
  },
  methods: {
    sendEmoji(event) {
      if (event.target.className.includes(this.emojiClass)) {
        EventBus.$emit("addToMessageInput", event.target.innerText);
      }
    }
  }
};
</script>

<style scoped>
.emojis {
  font-size: 25px;
}
.emojis ion-row ion-col {
  border: 1px solid #fff;
}
.emojis ion-row ion-col:hover {
  background-color: #ebebeb;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
}
</style>
