<template>
  <span @click="showEmojiPopover">
    <IconEmojis class="message-input-tool cursor-pointer icon-small" />
  </span>
</template>

<script>
import { addIcons } from "ionicons";
import { happy } from "ionicons/icons";

import popoverEmojis from "@/components/Tools/PopoverEmojis";
import { EventBus } from "@/services/Events";
import IconEmojis from "@/components/Global/Icons/IconEmojis";

addIcons({
  "md-happy": happy.md
});

export default {
  name: "EmojiTool",
  components: {
    IconEmojis
  },
  methods: {
    showEmojiPopover(ev) {
      this.$ionic.popoverController
        .create({
          component: popoverEmojis,
          // uncomment for popover snap to element
          event: ev,
          cssClass: "popover-width-lg popover-emoji",
          mode: "ios",
          componentProps: {
            data: {
              messageTitle: this.messageTitle,
              interactionId: this.interactionId,
              initiator: this.initiator,
              status: this.status
            }
          }
        })
        .then(m => m.present());
    },
    closePopover() {
      this.$ionic.popoverController.dismiss();
    }
  },
  created() {
    EventBus.$on("addToMessageInput", this.closePopover);
  }
};
</script>
