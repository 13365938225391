<template>
  <span>
    <IconAdd @click.native="showEducationOptions" class="message-input-tool icon-small add icon-grey" />
  </span>
</template>

<script>
import IconAdd from "@/components/Global/Icons/IconAdd";
import PopoverEducationOptions from "@/components/Chat/PopoverEducationOptions";

export default {
  name: "PatientEducationTool",
  components: {
    IconAdd
  },
  props: {
    beneficiaryId: Number
  },
  methods: {
    showEducationOptions(ev) {
      this.$ionic.popoverController
        .create({
          component: PopoverEducationOptions,
          event: ev,
          mode: "ios",
          cssClass: "popover-width-sm",
          componentProps: {
            data: {
              messageTitle: this.messageTitle,
              interactionId: this.interactionId,
              initiator: this.initiator,
              msgChannel: this.msgChannel,
              status: this.status,
              beneficiaryId: this.beneficiaryId,
              urgency: this.urgency
            }
          }
        })
        .then(p => p.present());
    }
  }
};
</script>

<style scoped>
.add.icon-grey >>> path {
  stroke: var(--ion-color-medium-shade);
}
.add.icon-grey:hover >>> path {
  stroke: currentColor;
  transition: stroke 0.2s ease-in-out;
}
svg {
  cursor: pointer;
}
</style>
