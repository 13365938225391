<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <IconPaper slot="start" class="icon-small" />
        <h5 slot="start">Send Patient Educational Content</h5>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-education-modal-content">
      <ion-slides pager="false" ref="educationSlider">
        <ion-slide>
          <div id="educationSearch">
            <input id="educationSearchInput" v-model="searchTerm" @keyup.enter="getArticlePreviews" class="form-control large-input" type="text" placeholder="Search for articles..." />
            <ion-icon id="educationSearchButton" name="search" @click="getArticlePreviews" />
          </div>
          <div v-if="searchLoading" class="prime-education-modal-search-content center-center">
            <ion-spinner name="dots" duration="1200" color="black" />
          </div>
          <div v-if="!searchLoading" class="prime-education-modal-search-content">
            <EducationPreviewCard v-for="preview in articlePreviews" :key="preview.id" @loaderClick="goToLoader($event)" :preview="preview" />
          </div>
        </ion-slide>
        <ion-slide>
          <div class="center-center full-width">
            <ion-spinner v-if="loaderIsLoading" name="dots" duration="1200" />
          </div>
          <div style="overflow: auto;">
            <EducationLoader v-if="!loaderIsLoading" :content="articleContent" />
          </div>
        </ion-slide>
      </ion-slides>
    </ion-content>
    <ion-footer v-if="sliderFocus === 'loader'">
      <ion-grid>
        <ion-row>
          <ion-col>
            <button @click="goToSearch" class="prime-button button-secondary button-block">
              Back to Search Results
            </button>
          </ion-col>
          <ion-col>
            <button @click="sendArticle" class="prime-button button-block" :class="{ 'button-pending': sendArticleLoading }">
              <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
              <span>Send Article <IconPaper height="22px"/></span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import EducationLoader from "@/components/Education/EducationLoader";
import EducationPreviewCard from "@/components/Education/EducationPreviewCard";
import { EventBus } from "@/services/Events";
import { send as httpSend } from "@/services/Api";
import IconPaper from "@/components/Global/Icons/IconPaper";

export default {
  name: "EducationModal",
  components: {
    IconPaper,
    EducationLoader,
    EducationPreviewCard
  },
  props: {
    beneficiaryId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      pageCopy: this.$languagePack.education,
      sendArticleLoading: false,
      searchLoading: false,
      articleContent: {
        body: "",
        head: "",
        title: ""
      },
      swiperOption: {
        allowTouchMove: false,
        spaceBetween: 40,
        autoHeight: false
      },
      sliderFocus: "search",
      articleId: 0,
      loaderIsLoading: true,
      articlePreviews: {},
      searchTerm: ""
    };
  },
  mounted() {
    const slides = this.$refs.educationSlider;
    slides.options = this.swiperOption;
  },
  methods: {
    async goToLoader(articleId) {
      this.articleId = articleId;
      this.getArticle();
    },
    goToSearch() {
      this.sliderFocus = "search";
      this.$refs.educationSlider.slideTo(0);
    },
    closeModal() {
      this.$ionic.modalController.dismiss({ description: null });
    },
    async getArticle() {
      const method = "GET";
      const path = document.config.getArticle.professional + this.articleId;
      this.loaderIsLoading = true;
      this.$refs.educationSlider.slideTo(1);
      this.sliderFocus = "loader";

      try {
        const result = await httpSend({
          path,
          method,
          authToken: true
        });
        const article = result.data[0];
        this.articleContent.head = article.headContent;
        this.articleContent.body = article.bodyContent;
        this.articleContent.title = article.title;
        this.articleContent.inlineJavascript = article.inlineJavascript;
        this.articleContent.javascriptSources = article.javascriptSources;
        this.articleContent.mediaContent = article.mediaContent;
      } catch (error) {
        this.handleError(error, this.pageCopy.errors.articleError);
      } finally {
        this.loaderIsLoading = false;
      }
    },
    async getArticlePreviews() {
      this.searchLoading = true;

      const method = "GET";
      const path = document.config.articleSearch + this.searchTerm;

      try {
        const result = await httpSend({
          path,
          method,
          authToken: true
        });
        const articlePreviews = result.data.data.searchResults.Article;
        this.articlePreviews = articlePreviews;
        if (!articlePreviews) {
          this.handleError("No articles found", this.pageCopy.errors.noArticles);
        }
      } catch (error) {
        this.handleError(error, this.pageCopy.errors.searchError);
      } finally {
        this.searchLoading = false;
      }
    },
    async sendArticle() {
      this.sendArticleLoading = true;
      const method = "POST";
      const path = document.config.sendArticle;
      const payload = {
        beneficiary_id: this.beneficiaryId,
        content_id: this.articleId,
        title: this.articleContent.title
      };

      try {
        const result = await httpSend({
          path,
          payload,
          method,
          authToken: true
        });

        const tinyUrl = result.data.tinyUrl;
        EventBus.$emit("addToMessageInput", tinyUrl);
        this.closeModal();
      } catch (error) {
        this.handleError(error, this.pageCopy.errors.sendError);
      } finally {
        this.sendArticleLoading = false;
      }
    },
    handleError(error, message) {
      this.$ionic.toastController
        .create({
          header: message,
          message: error,
          duration: 10000,
          position: "top"
        })
        .then(m => m.present());
      khanSolo.log(error);
    }
  }
};
</script>

<style scoped>
.swiper-container {
  height: 100%;
}
.swiper-slide {
  flex-direction: column;
  align-items: start;
}
.prime-education-modal-content {
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
}
.prime-education-modal-search-content {
  background: var(--ion-modal-secondary-background);
  border-top: 2px solid var(--ion-color-light-shade);
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow: auto;
}
ion-footer {
  padding: 20px;
}
ion-footer ion-col:first-child {
  padding-right: 5px;
}
ion-footer ion-col:not(:first-child) {
  padding-left: 5px;
}
:focus {
  outline: none !important;
}
#educationSearch {
  position: relative;
  padding: 0 20px;
  margin-bottom: 20px;
  width: 100%;
}
#educationSearchButton {
  position: absolute;
  cursor: pointer;
  color: var(--ion-color-medium-shade);
  height: 24px;
  width: 24px;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 40px;
}
#educationSearchButton:hover {
  color: var(--ion-color-primary);
}
</style>
