<template>
  <ion-list mode="ios">
    <ion-item v-if="$can(I.VIEW_HEALTH_EDUCATION)" @click="showEducationModal()" lines="none" class="cursor-pointer">
      <IconPaper class="icon-small margin-right-five" />
      <ion-label color="dark" mode="ios">
        Find Health Education Article
      </ion-label>
    </ion-item>
    <ion-item v-if="$can(I.VIEW_SUGGESTED_PRODUCTS)" @click="showSuggestedProductsModal()" lines="none" class="cursor-pointer">
      <IconCart class="icon-small margin-right-five" />
      <ion-label color="dark" mode="ios">
        Find Suggested Products
      </ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
import EducationModal from "@/components/Education/EducationModal";
import SuggestedProductsModal from "@/components/Education/SuggestedProductsModal";
import IconPaper from "@/components/Global/Icons/IconPaper";
import IconCart from "@/components/Global/Icons/IconCart";

export default {
  name: "PopoverEducationOptions",
  props: {},
  components: {
    IconPaper,
    IconCart
  },
  methods: {
    async showEducationModal() {
      const modalProperties = {
        mode: "ios",
        cssClass: "prime-modal-wide",
        component: EducationModal,
        componentProps: {
          propsData: {
            beneficiaryId: this.beneficiaryId
          }
        }
      };
      const modal = await this.$ionic.modalController.create(modalProperties);
      return modal.present();
    },
    async showSuggestedProductsModal() {
      const modalProperties = {
        mode: "ios",
        cssClass: "prime-modal-wide",
        component: SuggestedProductsModal,
        componentProps: {
          propsData: {
            beneficiaryId: this.beneficiaryId
          }
        }
      };
      const modal = await this.$ionic.modalController.create(modalProperties);
      return modal.present();
    }
  }
};
</script>

<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
</style>
