<template>
  <ion-card class="prime-article-search-card" @click="goToLoader">
    <div class="article-icon">
      <IconPaper class="message-input-tool icon-small" />
    </div>
    <div class="prime-article-search-card-content">
      <div class="prime-article-search-card-title">
        {{ preview.primaryText }}
      </div>
      <div class="prime-article-search-card-excerpt">
        {{ preview.secondaryText }}
      </div>
    </div>
  </ion-card>
</template>

<script>
import IconPaper from "@/components/Global/Icons/IconPaper";

export default {
  name: "EducationPreviewCard",
  components: {
    IconPaper
  },
  props: {
    preview: Object
  },
  methods: {
    goToLoader() {
      this.$emit("loaderClick", this.preview.id);
    }
  }
};
</script>

<style scoped>
ion-card.prime-article-search-card {
  background: #ffffff;
  color: var(--ion-text-color);
  display: flex;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.prime-article-search-card .article-icon {
  padding: 10px;
}
.prime-article-search-card-content {
  padding: 10px;
  text-align: left;
}
ion-card.prime-article-search-card:hover {
  box-shadow: 0 3px 15px 0px rgba(46, 61, 73, 0.3) !important;
}
ion-card.prime-article-search-card .prime-article-search-card-title {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 5px;
}
ion-card.prime-article-search-card .prime-article-search-card-excerpt {
  font-size: 13px;
  line-height: 1.3em;
  font-weight: 500;
  margin-top: 2px;
  color: var(--ion-color-medium-shade);
  width: 100%;
}
</style>
