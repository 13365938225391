<template>
  <ion-card class="prime-product-search-card" @click="goToLoader">
    <div class="prime-product-search-card-content">
      <div class="img-box">
        <img :src="imageUrl(preview.product.images)" alt="Product Image" width="100px" height="100px" />
      </div>
      <div>
        <div class="prime-product-search-card-title">
          {{ preview.product.name }}
        </div>
        <div class="prime-product-search-card-excerpt">
          {{ preview.product.shortDescription }}
        </div>
      </div>
    </div>
  </ion-card>
</template>

<script>
export default {
  name: "ProductPreviewCard",
  props: {
    preview: Object
  },
  methods: {
    imageUrl(val) {
      if (val && val.length) {
        return JSON.parse(val).originals[0];
      } else return "";
    },
    goToLoader() {
      this.$emit("loaderClick", this.preview.product.id);
    }
  }
};
</script>

<style scoped>
ion-card.prime-product-search-card {
  background: #ffffff;
  color: var(--ion-text-color);
  display: flex;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.prime-product-search-card .article-icon {
  padding: 10px;
}
.prime-product-search-card-content {
  padding: 5px;
  text-align: left;
  display: flex;
  gap: 15px;
}
ion-card.prime-product-search-card:hover {
  box-shadow: 0 3px 15px 0px rgba(46, 61, 73, 0.3) !important;
}
ion-card.prime-product-search-card .prime-product-search-card-title {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 5px;
}
ion-card.prime-product-search-card .prime-product-search-card-excerpt {
  font-size: 13px;
  line-height: 1.3em;
  font-weight: 500;
  margin-top: 2px;
  color: var(--ion-color-medium-shade);
  width: 100%;
}
.img-box {
  max-height: 100px;
}
</style>
