<template>
  <span>
    <IconBug @click.native="toggleDbugga" v-if="dbuggaMode" v-tooltip="'Disable dbugga mode for this conversation'" />
    <IconBugOutline @click.native="toggleDbugga" v-else v-tooltip="'Enable dbugga mode for this conversation'" />
  </span>
</template>

<script>
import IconBug from "@/components/Global/Icons/IconBug";
import IconBugOutline from "@/components/Global/Icons/IconBugOutline";

export default {
  name: "DbuggaTool",
  components: {
    IconBug,
    IconBugOutline
  },
  props: {},
  computed: {
    dbuggaMode() {
      return this.$store.getters["dbugga/dbuggaMode"];
    }
  },
  methods: {
    async toggleDbugga() {
      this.$store.commit("dbugga/toggleDbuggaMode");
    }
  }
};
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
